/* eslint-disable */
export const getArraySum = (arr) => {
  function arrSum (total, num) {
    return total + num;
  }
  return arr.reduce(arrSum);
}

export const CalculateModernPopColor = (value) => {
  if(value > 0 && value <= 1) {
    return '#99da44';
  }
  if(value > 1 && value <= 2) {
    return '#7abb25';
  }
  if(value > 2 && value <= 3) {
    return '#7abb25';
  }
  if(value > 3 && value <= 4) {
    return '#f8961e';
  }
  if(value > 4 && value <= 5) {
    return '#f3722c';
  }
  if(value > 5 && value <= 6) {
    return '#f94144';
  }
  if(value > 6 && value <= 7) {
    return '#168aad';
  }
  if(value > 7 && value <= 8) {
    return '#168aad';
  }
  if(value > 8 && value <= 9) {
    return '#168aad';
  }
  if(value > 9 && value <= 10) {
    return '#168aad';
  }
  return '#168aad';
}

export const CalculateModernMapPopColor = (value) => {
  if(value > 0 && value <= 0.5) {
    return '#FF0A54';
  }

  if(value > 0.5 && value <= 1) {
    return '#FF155C';
  }
  if(value > 1 && value <= 1.5) {
    return '#FF155C';
  }
  if(value > 1.5 && value <= 2.0) {
    return '#FF2B6B';
  }
  if(value > 2 && value <= 2.5) {
    return '#FF3673';
  }
  if(value > 2.5 && value <= 3) {
    return '#FF417A';
  }
  if(value > 3 && value <= 3.5) {
    return '#FF4C82';
  }
  if(value > 3.5 && value <= 4) {
    return '#FF578A';
  }
  if(value > 4 && value <= 4.5) {
    return '#FF6291';
  }
  if(value > 4.5 && value <= 5) {
    return '#FF6D99';
  }
  if(value > 5 && value <= 5.5) {
    return '#FF79A1';
  }
  if(value > 5.5 && value <= 6) {
    return '#FF84A9';
  }
  if(value > 6 && value <= 6.5) {
    return '#FF8FB0';
  }
  if(value > 6.5 && value <= 7) {
    return '#FF9AB8';
  }
  if(value > 7 && value <= 7.5) {
    return '#FFA5C0';
  }
  if(value > 7.7 && value <= 8) {
    return '#FFB0C7';
  }
  if(value > 8 && value <= 8.5) {
    return '#FFBBCF';
  }
  if(value > 8.5 && value <= 9) {
    return '#FFC6D7';
  }
  if(value > 9 && value <= 9.5) {
    return '#FFD1DE';
  }
  if(value > 9.5 && value <= 20) {
    return '#FFDCE6';
  }
  return '#FFDCE6';
}

export const plotlyMarkers = [
  [0, 6], [13, 6], [14, 6], [15, 6]
];

export const plotlyMarkers3dGecko = [
  ["circle", 2], ["square", 2], ["diamond", 3],
  ["circle-open", 3], ["square-open", 3], ["diamond-open", 4],
  /*["cross", 4],*/ ["x", 3]
];

export const plotlyMarkers3d = [
  ["circle", 4], ["square", 4], ["diamond", 3],
  ["circle-open", 5], ["square-open", 4], ["diamond-open", 4],
  /*["cross", 4],*/ ["x", 2]
];

export const pcaColors = (skin) => {
  if (skin === 'dark') {
    return {
      hovermode: 'closest',
      dragmode: 'pan',
      legend_color: '#ddd',
      paper_bgcolor: '#181818',
      plot_bgcolor: '#181818',
      font_color: '#444',
      label_font_color: '#fff'
    };
  }
  return {
    hovermode: 'closest',
    dragmode: 'pan',
    legend_color: '#3d3d3d',
    paper_bgcolor: '#fff',
    plot_bgcolor: '#fff',
    font_color: '#c4c4c4',
    label_font_color: '#0e0c0c'
  };
};
