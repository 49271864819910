export default {
    state: {
        loading: false,
        googleLoadMap: null,
    },
    getters: {
        getLoader: state => state.loading,
        getMapLoader: state => state.googleLoadMap,
    },
    mutations: {
        setLoader(state, value) {
            state.loading = value
        },
        setMapLoader(state, value) {
            state.googleLoadMap = value
        },
    },
}
