import ApiService from '@/common/api.service'

/* eslint-disable */
const getDefaultState = () => {
  return {
    selectedModule: 'ANCIENT',
    selectedCard: 'Map',
    locationQueryList: [],

    filterQuery: {
      Search: '',
      location: '',
      PageIndex: 1,
      PageSize: 20,
      Count: 200,
    },
    selectedPopDetail: null,
  }
};

const state = getDefaultState();

const getters = {
  getFilterQuery(state) {
    return state.filterQuery;
  },
}

const mutations = {
  setFilterData(state, data) {
    data.locationList.unshift('');
    state.locationQueryList = data.locationList;
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  resetFilterQuery(state) {
    state.filterQuery = {
      Search: '',
      location: '',
      PageIndex: 1,
      PageSize: 20,
      Count: 200,
    };
  },
}

const actions = {
  fetchAncientProxDatas(context, queryParams) {
    const copyParams = { ...queryParams };
    if (copyParams.Search) {
      copyParams.Search = copyParams.Search.replaceAll(/ /g, '*');
    }

    return new Promise((resolve, reject) => {
      ApiService.post(`ancientproxdata/getall`, copyParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
  fetchAncientProxFilterQuery(context, url) {
    return ApiService.get(url, {}, this)
      .then(response => {
        context.commit('setFilterData', response.result);
      })
  },

  fetchAncientIndividualData(context, queryParams) {
    const copyParams = { ...queryParams };
    if (copyParams.Search) {
      copyParams.Search = copyParams.Search.replaceAll(/ /g, '*');
    }

    return new Promise((resolve, reject) => {
      ApiService.post(`PresetIndividualSample/getall`, copyParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
