import { regionDefaultId, regionGrupDefaultId } from '@/common/config';

/* eslint-disable */
const breakDownHelpers = {
  _SetSelectedAncestryGrup(state, value) {
    if (value) {
      state.selectedRegionGrupId = value;
    } else {
      state.selectedRegionGrupId = regionGrupDefaultId;
    }
  },

  _SetSelectedRegion(state, IllusDnaService, value) {
    if (value === null) {
      value = regionDefaultId;
    }

    if (value === 1) {
      IllusDnaService.dataObject.numberOfPopulation = state.numberOfGlobalnPop;
    } else {
      IllusDnaService.dataObject.numberOfPopulation = state.numberOfDefaulnPop;
    }
    state.selectedRegionId = value;
  },

  _SetAncestryPureResult(state, result) {
    state.regionPureResults = JSON.parse(JSON.stringify(result));
    const tempRegionResults = JSON.parse(JSON.stringify(result));
    tempRegionResults.outPuts = [];
    result.outPuts.forEach(value => {
      const splitValue = value.resultsTable.split(':');
      if (splitValue.length > 0) {
        let findValue = tempRegionResults.outPuts.find(x => x.resultsTable === splitValue[0]);
        if (findValue) {
          findValue.currentResult += value.currentResult;
        } else {
          value.resultsTable = splitValue[0];
          tempRegionResults.outPuts.push(value);
        }
      }
    });
    tempRegionResults.outPuts.sort((a, b) => b.currentResult - a.currentResult);
    state.regionResults = tempRegionResults;
  },

  // Modern Break Down
  _SetModernBreakDownResult(state, selectedResultId, result) {
    this._SetModernBreakDownResults(state.modernBreadDownResults, selectedResultId, result);
  },

  _SetModernBreakDownResults(stateResults, selectedResultId, result) {
    const tempRegionResults = JSON.parse(JSON.stringify(result));
    if (!tempRegionResults.isCalculated) {
      tempRegionResults.outPuts = [];
      result.outPuts.forEach(value => {
        const splitValue = value.resultsTable.split(':');
        if (splitValue.length > 0) {
          let firstGroup = tempRegionResults.outPuts.find(x => x.resultsTable === splitValue[0]);
          if (firstGroup) {
            firstGroup.currentResult += value.currentResult;
          } else {
            firstGroup = { resultsTable: splitValue[0], currentResult: value.currentResult, subResult: [] };
            tempRegionResults.outPuts.push(firstGroup);
          }

          let secondGroup = firstGroup.subResult.find(x => x.resultsTable === splitValue[1]);
          if (secondGroup) {
            secondGroup.currentResult += value.currentResult;
          } else {
            secondGroup = { resultsTable: splitValue[1], currentResult: value.currentResult, subResult: [] };
            firstGroup.subResult.push(secondGroup);
          }

          let thirdGroup = secondGroup.subResult.find(x => x.resultsTable === splitValue[2]);
          if (thirdGroup) {
            thirdGroup.currentResult += value.currentResult;
          } else {
            thirdGroup = { resultsTable: splitValue[2], currentResult: value.currentResult, subResult: [] };
            secondGroup.subResult.push(thirdGroup);
          }
        }
      });
    }
    // this.IllusDnaService.dataObject.numberOfPopulation = tempPop;
    tempRegionResults.outPuts.sort((a, b) => b.currentResult - a.currentResult);
    const modernBreakDown = stateResults.find(x => x.selectedResultId === selectedResultId);
    if (modernBreakDown) {
      modernBreakDown.result = tempRegionResults;
    } else {
      stateResults.push({
        selectedResultId: selectedResultId,
        result: tempRegionResults,
      });
    }
  },

  _SetModernBreakDownSourceDataActive(state, IllusDnaService, sourceData) {
    if (state.modernBreadDownSourceDataActive.length === 0) {
      const sourceDatas = [];
      if(sourceData) {
        const resolveSource = IllusDnaService.codeDataToArray(sourceData);
        if (resolveSource.isSuccess) {
          resolveSource.result.forEach(item => {
            let sourceCodes = item[0];
            if (sourceCodes.includes(':')) {
              sourceCodes = sourceCodes.split(':');
            }
            if (!sourceDatas.find(x => x.sourceCode === sourceCodes[1])) {
              sourceDatas.push({
                codeLabel: sourceCodes[0] + ': ' + sourceCodes[1],
                sourceCode: sourceCodes[1],
                isActive: false,
              });
            }
          });
        }
        sourceDatas.sort((a,b) => (a.codeLabel > b.codeLabel) ? 1 : ((b.codeLabel > a.codeLabel) ? -1 : 0))
      }
      state.modernBreadDownSourceDataActive = sourceDatas;
    }
  },

  _FilterModernBreakDownSourceData(IllusDnaService, filteredData) {
    const selectedData = filteredData.filter(x => x.isActive);
    IllusDnaService.dataArrays.sourceCodeArray = IllusDnaService.dataArrays.sourceCodeArray.filter(item => {
      let sourceCode = item[0];
      if (sourceCode.includes(':')) {
        sourceCode = sourceCode.split(':')[1];
      }
      if(selectedData.find(x => x.sourceCode === sourceCode)) {
        return true;
      }
      return false;
    });
    IllusDnaService.dataArrays.sourceCodeNumber = IllusDnaService.dataArrays.sourceCodeArray.length;
  },

  // Custom Ancestry Break Down
  _SetCustomAncBrDnResult(state, selectedResultId, result) {
    this._SetCustomABResults(state.customAncBrDnResults, selectedResultId, result);
  },

  _SetCustomABResults(stateResults, selectedResultId, result) {
    const regionResults = JSON.parse(JSON.stringify(result));
    const tempRegionResults = JSON.parse(JSON.stringify(result));
    tempRegionResults.outPuts = [];
    result.outPuts.forEach(value => {
      const splitValue = value.resultsTable.split(':');
      if (splitValue.length > 0) {
        let findValue = tempRegionResults.outPuts.find(x => x.resultsTable === splitValue[0]);
        if (findValue) {
          findValue.currentResult += value.currentResult;
        } else {
          value.resultsTable = splitValue[0];
          tempRegionResults.outPuts.push(value);
        }
      }
    });
    
    tempRegionResults.outPuts.sort((a, b) => b.currentResult - a.currentResult);
    const farmerHGsResult = stateResults.find(x => x.selectedResultId === selectedResultId);
    if (farmerHGsResult) {
      farmerHGsResult.pureResult = regionResults;
      farmerHGsResult.result = tempRegionResults;
    } else {
      stateResults.push({
        selectedResultId: selectedResultId,
        result: tempRegionResults,
        pureResult: regionResults
      });
    }
  },

  _SetCustomizeSourceDataActive(state, IllusDnaService, sourceData) {
    const sourceDatas = [];
    if(sourceData) {
      const resolveSource = IllusDnaService.codeDataToArray(sourceData);
      if (resolveSource.isSuccess) {
        resolveSource.result.forEach(item => {
          let sourceCode = item[0];
          if (sourceCode.includes(':')) {
            sourceCode = sourceCode.split(':')[0];
          }
          if (!sourceDatas.find(x => x.sourceCode === sourceCode)) {
            sourceDatas.push({
              sourceCode,
              isActive: true,
            });
          }
        });
      }
      sourceDatas.sort((a,b) => (a.sourceCode > b.sourceCode) ? 1 : ((b.sourceCode > a.sourceCode) ? -1 : 0))
    }
    state.customAncBrDnSourceDataActive = sourceDatas;
  },

  _FilterCustomizSourceData(IllusDnaService, filteredData) {
    const selectedData = filteredData.filter(x => x.isActive);
    IllusDnaService.dataArrays.sourceCodeArray = IllusDnaService.dataArrays.sourceCodeArray.filter(item => {
      let sourceCode = item[0];
      if (sourceCode.includes(':')) {
        sourceCode = sourceCode.split(':')[0];
      }
      if(selectedData.find(x => x.sourceCode === sourceCode)) {
        return true;
      }
      return false;
    });
    IllusDnaService.dataArrays.sourceCodeNumber = IllusDnaService.dataArrays.sourceCodeArray.length;
  },

  // Periodical Ancestry Break Down
  _SetPeriodicalAncBrDnResult(state, selectedResultId, result) {
    this._SetPeriodicalABResults(state.periodicalAncBrDnResults, selectedResultId, state.selectedAncientCalculator.id, result);
  },

  _SetPeriodicalABResults(stateResults, selectedResultId, selectedAncientCalculatorId, result) {
    const regionResults = JSON.parse(JSON.stringify(result));
    const tempRegionResults = JSON.parse(JSON.stringify(result));
    tempRegionResults.outPuts = [];
    result.outPuts.forEach(value => {
      const splitValue = value.resultsTable.split(':');
      if (splitValue.length > 0) {
        let findValue = tempRegionResults.outPuts.find(x => x.resultsTable === splitValue[0]);
        if (findValue) {
          findValue.currentResult += value.currentResult;
        } else {
          value.resultsTable = splitValue[0];
          tempRegionResults.outPuts.push(value);
        }
      }
    });
    
    tempRegionResults.outPuts.sort((a, b) => b.currentResult - a.currentResult);
    const farmerHGsResult = stateResults.find(x => x.selectedResultId === selectedResultId && x.selectedAncientCalculatorId === selectedAncientCalculatorId);
    if (farmerHGsResult) {
      farmerHGsResult.pureResult = regionResults;
      farmerHGsResult.result = tempRegionResults;
    } else {
      stateResults.push({
        selectedResultId: selectedResultId,
        selectedAncientCalculatorId: selectedAncientCalculatorId,
        result: tempRegionResults,
        pureResult: regionResults
      });
    }
  },
}

export default breakDownHelpers;