import Vue from 'vue'
// axios
import axios from 'axios'

import { API_URL } from '@/common/config'

/* eslint-disable */
const axiosIns = axios.create({
  baseURL: API_URL,
  // baseURL: 'https://localhost:5001/api/',
  headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT' },
})

// Add a response interceptor
axiosIns.interceptors.response.use(response => {
  try {
    if (typeof response.data.result === 'string') {
      response.data.result = JSON.parse(response.data.result);
    }
  } catch {
    // response.data = response.data;
  }
  return response.data;
}, error => Promise.reject(error));

Vue.prototype.$http = axiosIns

export default axiosIns
