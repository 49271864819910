import ApiService from '@/common/api.service'
import breakDownHelpers from './breakDownHelpers';
/* eslint-disable */
const getDefaultState = () => {
  return {
    modernBreakDownnPop: 3,
    modernBreadDownSourceData: [],
    modernBreakDownSelectedAll: false,
    modernBreadDownSourceDataActive: [],
    modernBreadDownResults: [],
    modernBreadDownFinalResult: [],
  }
};

const state = getDefaultState();

const getters = {
  getModernBreakDownResult(state, getters, rootState) {
    const findResult = state.modernBreadDownResults.find(x => x.selectedResultId === rootState.orderResult.selectedResultId);
    if (findResult) {
      return findResult.result;
    }
    return [];
  },
  getModernBreakDownFinalResult(state) {
    return state.modernBreadDownFinalResult;
  },
}

const mutations = {
  setModernBreakDownSourceData(state, modernBreakDownResultData) {
    breakDownHelpers._SetModernBreakDownSourceDataActive(state, this.state.orderResult.IllusDnaService, modernBreakDownResultData.source);
  
    state.modernBreadDownSourceData = modernBreakDownResultData.source;
    if (modernBreakDownResultData.isHaseResult && modernBreakDownResultData.result !== '[]') {
      const modernResult = JSON.parse(modernBreakDownResultData.result);
      modernResult.isCalculated = true;
      breakDownHelpers._SetModernBreakDownResult(state, this.state.orderResult.selectedResultId, modernResult);
    }
  },
  runSingleModernBreakDown(state) {
    const tempPop = this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation;
    this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation = state.modernBreakDownnPop;
    
    const resolveSource = this.state.orderResult.IllusDnaService.codeDataToArray(state.modernBreadDownSourceData);
    if (resolveSource.isSuccess) {
      this.state.orderResult.IllusDnaService.onSetSource(resolveSource.result);
      if(state.modernBreadDownSourceDataActive.length) {
        breakDownHelpers._FilterModernBreakDownSourceData(this.state.orderResult.IllusDnaService, state.modernBreadDownSourceDataActive);
      }
    }
    
    const result = this.state.orderResult.IllusDnaService.runABAlgorithm();
    breakDownHelpers._SetModernBreakDownResult(state, this.state.orderResult.selectedResultId, result);
    this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation = tempPop;
  },
  setModernBreakDownFinalResults(state, result) {
    state.modernBreadDownFinalResult = result;
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchModernBreakDownSourceDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('sourcedata/getmodernbreakdown', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
