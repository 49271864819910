import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

/* eslint-disable */
const getDefaultState = () => {
  return {
    regionGrups: [],
    pcaRegionGrups: [],
    regions: [],
    pcaRegions: [],
    ancestryGeoJson: [],
    modernPopGeoJson: null,
    modernPopGeoJsonResult: null,
    ancientPeriods: [],
    selectedAncientPeriod: {id: 0, number: 0, name: ''},
  }
}

const state = getDefaultState()

const getters = {

  // order result
  getRegionGrups(state) {
    return state.regionGrups;
  },
  getPcaRegionGrups(state) {
    return state.pcaRegionGrups;
  },
  getRegions(state) {
    return state.regions;
  },
  getPcaRegions(state) {
    return state.pcaRegions;
  },

  getAncestryGeoJson(state) {
    return state.ancestryGeoJson;
  },

  getModernPopGeoJson(state) {
    return state.modernPopGeoJson;
  },
  getModernPopGeoJsonResult(state) {
    return state.modernPopGeoJsonResult;
  },

  getAncientPeriods(state) {
    return state.ancientPeriods;
  },
}

const mutations = {
  // order result
  setRegionGrups(state, results) {
    state.regionGrups = results.filter(x => x.presetRegionGrupType === 1);
    state.pcaRegionGrups = results.filter(x => x.presetRegionGrupType === 2);
  },
  setRegions(state, results) {
    state.regions = results.filter(x => x.presetRegionType === 1);
    state.pcaRegions = results.filter(x => x.presetRegionType === 2);
  },

  setAncestryGeoJson(state, ancestryGeoJson) {
    state.ancestryGeoJson = ancestryGeoJson;
  },

  setMdernPopGeoJson(state, modernPopGeoJson) {
    state.modernPopGeoJson = modernPopGeoJson;
  },
  setMdernPopGeoJsonResult(state, modernPopGeoJsonResult) {
    state.modernPopGeoJsonResult = modernPopGeoJsonResult;
  },

  // Ancient Periods
  setAncientPeriods(state, ancientPeriods) {
    state.ancientPeriods = ancientPeriods;
    state.selectedAncientPeriod = ancientPeriods[0];
  },
  setSelectedPeriod(state, selectedPeriod) {
    state.selectedAncientPeriod = selectedPeriod;
  },
}

const actions = {
  fetchRegions(context, queryParams) {
    return ApiService.get('PresetRegion/regions', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setRegions', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
  fetchRegionGrups(context, queryParams) {
    return ApiService.get('PresetRegion/regiongrups', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setRegionGrups', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  // Map Data
  fetchMapSourceDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('PresetMapData/bysourcedata', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  fetchMapSourceModern(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get('PresetMapData/modergeojson', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
  
  fetchMapDetailDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.getSilent(`PresetMapData/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  // Farmer And HGs Data
  fetchFarmerAndHGsDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('PresetFarmerAndHGs/bysourcedata', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
  fetchFarmerAndHGsDetailData(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`PresetFarmerAndHGs/${queryParams.sourceCode}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  // Ancient
  fetchAncientProxImageDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('ancientproxdata/bysourcedata', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  fetchAncientProxDetailDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.getSilent(`ancientproxdata/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  // Ancient Periods
  fetchAncientPeriods(context, queryParams) {
    return ApiService.get('PresetAncientPeriod', queryParams, this)
    .then(response => {
      if (response.statusCode === 200) {
        context.commit('setAncientPeriods', response.result)
      } else {
        AlertService.error(this._vm, response.message)
      }
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
