import ApiService from '@/common/api.service'
import PcaService from '@/common/custom-service/dna-source/PcaService'

/* eslint-disable */
const getDefaultState = () => {
  return {
    pcaSourceDatas: [],
    selectedPcaRegionId: null,
    selectedPcaRegionGrupId: null,
    pcaSkin: 'light',
    isEnableConvexHull: false,
  }
};

const state = getDefaultState();

const getters = {
  getOrderSelectedPcaRegion(state) {
    return state.selectedPcaRegionId;
  },
  getOrderSelectedPcaRegionGrup(state) {
    return state.selectedPcaRegionGrupId;
  },
}

const mutations = {
  setSelectedPcaRegion(state, region) {
    state.selectedPcaRegionId = region;
  },
  setSelectedPcaRegionGrup(state, regionGrup) {
    state.selectedPcaRegionGrupId = regionGrup;
  },

  setPcaSourceData(state, sourceDatas) {
    state.pcaSourceDatas = sourceDatas[0].source;
  },
  runPca(state) {
    PcaService.sourceObject.pcaColorMode = state.pcaSkin;
    PcaService.sourceObject.isEnableConvexHull = state.isEnableConvexHull;
    PcaService.sourceObject.selectedTargetTitle = this.state.orderResult.selectedTargetTitle;
    PcaService.onSetSourceDatas(state.pcaSourceDatas, this.state.orderResult.selectedTarget);
    PcaService.runPCA();
    PcaService.plotPCA();
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchPcaRegionSourceDatas(context, queryParams) {
    return ApiService.post('sourcedata/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setPcaSourceData', response.result);
          context.commit('runPca');
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
