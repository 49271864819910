import ApiService from '@/common/api.service'
import { TRAITS_RESULT_TABS } from '@/common/config'

/* eslint-disable */
const getDefaultState = () => {
  return {
    traitsDataResults: {},
    selectedTab: TRAITS_RESULT_TABS.TRAITS.code,
    traitsCategories: {},
    selectedTraitCategory: {},
    selectedSort: '',
  }
};

const state = getDefaultState();

const getters = {
  getTraitsDataResults(state) {
    return state.traitsDataResults;
  },
  
  getSelectedSort(state) {
    return state.selectedSort;
  },
}

const mutations = {
  setTraitsDataResults(state, resultData) {
    state.traitsDataResults[resultData.resultId] = resultData.results;
    
    state.traitsCategories[resultData.resultId] = resultData.results.map(x => x.traitCategory);
    
    if(state.traitsCategories[resultData.resultId].length && !state.selectedTraitCategory.name) {
      state.selectedTraitCategory = state.traitsCategories[resultData.resultId][0];
    }
  },
}

const actions = {
  fetchTraitsData(context, query) {
    return new Promise((resolve, reject) => {
      ApiService.post(`Traits`, query, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
  fetchTraitDetailData(context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(`Traits/get`, params, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
