<template>
  <div>
    <b-alert :variant="variant" show v-show="message">
      <div class="alert-body">
        <span>{{message}}</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
export default {
   name: 'Alert',
  components: {
  },
  props: {    
    variant: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>    
</style>
