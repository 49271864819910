import ApiService from '@/common/api.service'
import farmerHgsHelpers from './farmerHgsHelpers';
/* eslint-disable */
const getDefaultState = () => {
  return {
    farmerAndHGsSourceData: [],
    farmerAndHGsResults: [],
    farmerAndHGsFinalResult: [],
  }
};

const state = getDefaultState();

const getters = {
  getFarmerAndHGsResult(state, getters, rootState) {
    const findResult = state.farmerAndHGsResults.find(x => x.selectedResultId === rootState.orderResult.selectedResultId);
    if (findResult) {
      return findResult.result;
    }
    return [];
  },
  getFarmerAndHGsFinalResult(state) {
    return state.farmerAndHGsFinalResult;
  },
}

const mutations = {
  setFarmerAndHGsSourceData(state, farmerHGsResultData) {
    if (farmerHGsResultData.isHaseResult) {
      farmerHgsHelpers._SetFarmerHGsResult(state, this.state.orderResult.selectedResultId, JSON.parse(farmerHGsResultData.result));
    } else {
      state.farmerAndHGsSourceData = farmerHGsResultData.source;
      const resolveSource = this.state.orderResult.IllusDnaService.codeDataToArray(state.farmerAndHGsSourceData);
      if (resolveSource.isSuccess) {
        this.state.orderResult.IllusDnaService.onSetSource(resolveSource.result);
      }
    }
  },
  runSingleFarmerHGs(state) {
    const tempPop = this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation;
    this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation = 0;
    const result = this.state.orderResult.IllusDnaService.runABAlgorithm();
    
    farmerHgsHelpers._SetFarmerHGsResult(state, this.state.orderResult.selectedResultId, result);
    this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation = tempPop;
  },
  setFarmerAndHGsFinalResults(state, result) {
    state.farmerAndHGsFinalResult = result;
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchFarmerAndHGsSourceDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('sourcedata/getfarmerhgs', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
