import useJwt from '@/auth/jwt/useJwt'
import ApiService from '@/common/api.service'
import { getField, updateField } from 'vuex-map-fields';

/* eslint-disable */
const getDefaultState = () => {
  return {
    userData: {
      userEmail: null,
      firstName: null,
      lastName: null,
      password: null,
      twoFactorAuthActive: true,
    },
    isUserLoggedIn: false,
    logginUserData: JSON.parse(localStorage.getItem('userData')) || null,
  }
}

const state = getDefaultState()

const getters = {
  getUserDataField(state) {
    return getField(state.userData);
  },

  getUserData(state) {
    return state.userData;
  },

  getIsLoggedIn(state) {
    state.isUserLoggedIn  = false;
    if(localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)) {
      state.isUserLoggedIn = true;
    }
    
    return state.isUserLoggedIn;
  },
}

const mutations = {
  updateUserDataField(state, field) {
    updateField(state.userData, field);
  },
  updateUserEmail(state, userEmail) {
    state.userData.userEmail = userEmail;
  },

  setIsLoggedIn(state, status) {
    state.isUserLoggedIn = status;
  },
}

const actions = {
  orderSignUp(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('account/signup', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  sendResetPassowrdEmail(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`account/resetpassword/request/${queryParams.emailAddress}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  checkResetPasswordEmail(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`account/resetpassword/verify/${queryParams.verifyCode}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  changeResetPasswordEmail(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('account/resetpassword/change', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
