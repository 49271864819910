import ApiService from '@/common/api.service'
import { PRODUCT_CODES } from '@/common/config';

/* eslint-disable */
let kitObj = { 
  id: 0, 
  personName: '', 
  fileName: '', 
  presetEthnicityId: null,
  selectedRegionName: 'Select Ethnicity',
  isEmptyPresetEthnicityId: false,
  targetCoordinate: '', 
  preTempOrderRawDataGuid: null,
  emptyPreTempOrderRawDataGuid: false,
  isLoading: false,
  emptyPersonName: false,
  emptyTargetCoordinate: false,
  isShowDropdown: false,
};

const getDefaultState = () => {
  return {
    mobilePageCode: 'ACCOUNT',
    productData: null,
    partnerLinkData: null,
    createOrderData: {
      subtotal: null,
      discountSubtotal: null,
      currencyUnit: null,
      paymentType: 2,
      paymentGuid: null,
      product: null,
      note: null,
      productQuantity: 1,
      kits: [Object.assign({}, kitObj)],
      isFinishedPayment: false,
      isConfirmPrivacyPalicy: false,
      businessPartnerLinkGuid: null,
    },
  }
}

const state = getDefaultState()

const getters = {
  getCreateOrderData(state) {
    return state.createOrderData;
  },
  getCheckoutQuery(state) {
    const product = state.productData.product;
    const orderData = state.createOrderData;
    
    const queryParams = {
      subtotal: orderData.subtotal.toString().replace(',', '.'),
      discountSubtotal: orderData.discountSubtotal != null ? orderData.discountSubtotal.toString().replace(',', '.') : null,
      productQuantity: orderData.productQuantity,
      currencyUnit: product.currencyUnit,
      productId: product.id,
      paymentType: orderData.paymentType,
      paymentGuid: orderData.paymentGuid,
      person: orderData.kits[0],
      businessPartnerLinkGuid: state.partnerLinkData.guid,
    };
    
    return queryParams;
  },
  getOrderSubTotal(state) {
    return state.createOrderData.discountSubtotal === null ? state.createOrderData.subtotal : state.createOrderData.discountSubtotal;
  },
  getProductData(state) {
    return state.productData;
  },
}

const mutations = {
  setProductData(state, productData) {
    state.productData = productData;
    const findPrice = productData.productPrices.find(x => x.productCount === 1);
    if (findPrice) {
      const subTotal = findPrice.disCountedPrice.toString().replace('.', ',');
      state.createOrderData.subtotal = subTotal;

      if (state.partnerLinkData) {
        const discountTotal = findPrice.disCountedPrice - (findPrice.disCountedPrice * state.partnerLinkData.discount / 100);
        state.createOrderData.discountSubtotal = Math.round(discountTotal * 100) / 100;
      }
    }
  },
  setPartnerLinkData(state, partnerLinkData) {
    state.partnerLinkData = partnerLinkData;
    state.createOrderData.kits[0].personName = partnerLinkData.fullName;
  },
  setCreateOrderData(state, { key, value }) {
    state.createOrderData[key] = value;
  },

  resetOrderState(state) {
    kitObj = { 
      id: 0, 
      personName: '', 
      fileName: '', 
      preTempOrderRawDataGuid: null,
      emptyPreTempOrderRawDataGuid: false,
      isLoading: false,
      emptyPersonName: false,
      isShowDropdown: false,
    };
    Object.assign(state, getDefaultState())
  },

  closeDropdowns(state) {
    state.createOrderData.kits.forEach(element => {
      element.isShowDropdown = false;
    });
  },
}

const actions = {
  saveChecout(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('Checkout/g25-partner', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  createPaymentIntent(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.postSilent('payment/create/intent', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
  createPaymentDirect(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('order/payment/create/direct', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  fetchPartnerLink(context, guid) {
    return new Promise((resolve, reject) => {
      ApiService.post('BusinessPartner/check-guid', { linkGuid: guid }, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
