import ApiService from '@/common/api.service'

/* eslint-disable */
const getDefaultState = () => {
  return {
    modernMixedModePopNumber: '30',
    selectedPeriodId: 0,
    mixedModePeriods: [],
    mixedModeOrderResult: {},
    selectedModernMixedMode: {},
  }
};

const state = getDefaultState();

const getters = {
  getModernMixedModePopNumber(state) {
    return state.modernMixedModePopNumber;
  },
  getSelectedMixedModeResult(state) {
    return state.mixedModeOrderResult;
  },  
  getSelectedModernMixedModeResult(state) {
    return state.selectedModernMixedMode;
  },
}

const mutations = {
  setMixedModePeriods(state, mixedModePeriods) {
    state.mixedModePeriods = mixedModePeriods;
    state.selectedPeriodId = mixedModePeriods[0].id;
  },
  setMixedModerPeriodId(state, selectedPeriodId) {
    state.selectedPeriodId = selectedPeriodId;
  },
  setMixedModeResult(state, mixedModeResult) {
    state.mixedModeOrderResult = mixedModeResult;
  },
  setSelectedModernMixedModeResult(state, selectedModernMixedMode) {
    state.selectedModernMixedMode = selectedModernMixedMode;
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchModernMixedModePeriods(context) {
    return ApiService.post('mixedmode/periods', { mixedModeType: 1 }, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setMixedModePeriods', response.result);
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchOrderMixedModeResults(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post(`mixedmode/result`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  updateOrderResultMixedModeResults(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post(`mixedmode/update`, { orderResultId: queryParams.id }, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
