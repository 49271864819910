import ApiService from '@/common/api.service'

/* eslint-disable */
const getDefaultState = () => {
  return {
    orderResults: [],
    selectedResult: { orderResultStatus: null },
  }
};

const state = getDefaultState();

const getters = {
  // order result
  getOrderResults(state) {
    return state.orderResults;
  },
  getSelectedOrderResultId(state) {
    return state.selectedResult.id;
  },
  getSelectedOrderResult(state) {
    return state.selectedResult;
  },
}

const mutations = {
  // order result
  setOrderResults(state, results) {
    if (results.length > 0) {
      state.orderResults = results;
    } else {
      state.selectedResult.orderResultStatus = 0;
    }
  },

  setTargetData(state, value) {
    state.selectedResult = value;
  },
}

const actions = {
  fetchOrderResults(context) {
    return new Promise((resolve, reject) => {
      ApiService.get(`OrderResultsTrait`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
