import ApiService from '@/common/api.service'
import breakDownHelpers from './breakDownHelpers';
/* eslint-disable */
const getDefaultState = () => {
  return {
    isFixedRegion: false,
    isViewAllRegionMap: false,
    numberOfGlobalnPop: 5,
    numberOfDefaulnPop: 0,
    regionSourceDatas: [],
    regionResults: null,
    regionPureResults: null,
    regionFinalResults: null,
    selectedRegionId: null,
    selectedRegionGrupId: null,
  }
};

const state = getDefaultState();

const getters = {
  getRegionResults(state) {
    return state.regionResults;
  },
  getRegionPureResults(state) {
    return state.regionPureResults;
  },
  getRegionFinalResults(state) {
    return state.regionFinalResults;
  },

  getRegionSourceDatas(state) {
    return state.regionResults.outPuts.map(item => item.resultsTable);
  },

  getOrderSelectedRegionGrup(state) {
    return state.selectedRegionGrupId;
  },
  getOrderSelectedRegion(state) {
    return state.selectedRegionId;
  },
}

const mutations = {
  setRegionFinalResults(state, result) {
    state.regionFinalResults = result;
  },
  setNumberOfGlobalnPop(state, value) {
    state.numberOfGlobalnPop = value;
  },
  setSelectedRegionGrup(state, regionGrup) {
    breakDownHelpers._SetSelectedAncestryGrup(state, regionGrup);
  },
  setSelectedRegion(state, region) {
    breakDownHelpers._SetSelectedRegion(state, this.state.orderResult.IllusDnaService, region);
  },
  setRegionByTarget(state) {
    if(!state.isFixedRegion) {
      const result = this.state.orderResult.selectedResult;
      breakDownHelpers._SetSelectedAncestryGrup(state, result.presetRegionGrupId);
      breakDownHelpers._SetSelectedRegion(state, this.state.orderResult.IllusDnaService, result.presetRegionId);
    }
  },

  // Results
  setRegionSourceDatas(state, regionResultData) {
    if (regionResultData.isHaseResult && regionResultData.result !== '[]') {
      breakDownHelpers._SetAncestryPureResult(state, JSON.parse(regionResultData.result));
    }
    state.regionSourceDatas = regionResultData.source;
  },
  setAncestryPureResult(state, result) {
    breakDownHelpers._SetAncestryPureResult(state, result);
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchRegionSourceDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('PresetRegion/regionsourcedata', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
