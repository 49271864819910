import ApiService from '@/common/api.service'

const state = {
  notificationCount: 0,
  notifications: [],

  generalNotificationList: [],
}

const getters = {
  getNotificationCount(state) {
    return state.notificationCount;
  },

  getNotifications(state) {
    return state.notifications;
  },

  getAllGeneralNotifications(state) {
    return state.generalNotificationList;
  },
}

const mutations = {
  setNotification(state, notificationData) {
    state.notifications = notificationData.generalNotificationList;
    state.notificationCount = notificationData.notReadedCount;
  },

  setAllGeneralNotification(state, notificationData) {
    notificationData.forEach(x => {
      x.isOpenDesc = false;
    });
    state.generalNotificationList = notificationData;
  },
}

const actions = {
  fetchNotifications(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get('notification/getless', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
  fetchAllGeneralNotifications(context, queryParams) {
    return ApiService.getSilent('notification/getAll', queryParams, this)
      .then(response => {
        context.commit('setAllGeneralNotification', response.result)
      })
  },

  readNotifications(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.postSilent('notification/read', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
