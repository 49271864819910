import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn, getUserRole, Role } from '@/auth/utils'
import customer from './routes/customer'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { 
      path: '/', 
      redirect: { name: 'dashboard' },
      meta: {
        authorize: [Role.Customer],
        code: 'DASHBOARD',
      },
    },
    ...customer,

    {
      path: '/profile-settings',
      name: 'profile-settings',
      component: () => import('@/views/profile-settings/ProfileSettings.vue'),
      meta: {
        authorize: [],
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/account/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/account/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/loginguid/:code',
      name: 'loginguid',
      component: () => import('@/views/account/LoginGuid.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgotpassword',
      name: 'forgotpassword',
      component: () => import('@/views/account/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/resetpassword/:verifyCode',
      name: 'resetpassword',
      component: () => import('@/views/account/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/email-verify/:verifyCode',
      name: 'email-verify',
      component: () => import('@/views/account/EmailVerify.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta
  if (authorize) {
    const isLoggedIn = isUserLoggedIn()
    const currentUserRole = getUserRole()

    if (!isLoggedIn) {
      // not logged in so redirect to login page with the return url
      if (to.name === 'login') {
        return next();
      } 
      return next({ name: 'login' });
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUserRole)) {
      // role not authorised so redirect to home page
      if ((currentUserRole === Role.Customer) && to.name !== 'customer-home') {
        next({ name: 'customer-home' });
      } else {
        next({ path: '/' });
      }
    }
  }

  return next()
})

export default router
