import ApiService from '@/common/api.service'

const state = {
  generalSettings: {},
}

const getters = {
  getGeneralSettings(state) {
    return state.generalSettings
  },
}

const mutations = {
  setGeneralSettings(state, generalSettings) {
    state.generalSettings = generalSettings
  },
}

const actions = {
  fetchGeneralSettings() {
    return new Promise((resolve, reject) => {
      ApiService.get('user', {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  checkUserName(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.postSilent('user/checkusername', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  checkUserEmail(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.postSilent('user/checkemail', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveGeneralUserInfo(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('user', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  savePassword(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('user/password/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  deleteAccount(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('user/delete', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
