import ApiService from '@/common/api.service'
import breakDownHelpers from './breakDownHelpers';
/* eslint-disable */
const getDefaultState = () => {
  return {
    selectedAncientCalculator: { id: 0, sourceDataId: null, },
    periodicalAncBrDnnPop: 3,
    periodicalAncBrDnSourceData: null,
    periodicalAncBrDnResults: [],
    periodicalAncBrDnFinalResult: [],
  }
};

const state = getDefaultState();

const getters = {
  getSelectedCalculator(state) {
    return state.selectedAncientCalculator;
  },
  getPeriodicalAncBrDnResult(state, getters, rootState) {
    const findResult = state.periodicalAncBrDnResults.find(x => x.selectedResultId ===  rootState.orderResult.selectedResultId && x.selectedAncientCalculatorId === state.selectedAncientCalculator.id);
    if (findResult) {
      return findResult.result;
    }
    return [];
  },
  getPeriodicalAncBrPureResult(state, getters, rootState) {
    const findResult = state.periodicalAncBrDnResults.find(x => x.selectedResultId === rootState.orderResult.selectedResultId && x.selectedAncientCalculatorId === state.selectedAncientCalculator.id);
    if (findResult) {
      return findResult.pureResult;
    }
    return [];
  },
  getPeriodicalAncBrDnFinalResult(state) {
    return state.periodicalAncBrDnFinalResult;
  },
}

const mutations = {
  setSelectedCalculator(state, calculator) {
    state.selectedAncientCalculator = calculator;
  },
  setPeriodicalAnBrDnResult(state, customAnBrDnResultData) {
    state.periodicalAncBrDnSourceData = customAnBrDnResultData.source;
    if (customAnBrDnResultData.isHaseResult && customAnBrDnResultData.result !== '[]') {
      breakDownHelpers._SetPeriodicalAncBrDnResult(state, this.state.orderResult.selectedResultId, JSON.parse(customAnBrDnResultData.result));
    }
  },
  runSinglePeriodicalAnBrDn(state) {
    const tempPop = this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation;
    this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation = state.periodicalAncBrDnnPop;

    const resolveSource = this.state.orderResult.IllusDnaService.codeDataToArray(state.periodicalAncBrDnSourceData);
    if (resolveSource.isSuccess) {
      this.state.orderResult.IllusDnaService.onSetSource(resolveSource.result);
    }
    
    const result = this.state.orderResult.IllusDnaService.runABAlgorithm();
    breakDownHelpers._SetPeriodicalAncBrDnResult(state, this.state.orderResult.selectedResultId, result);
    this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation = tempPop;
  },
  setPeriodicalAncBrDnResultFinalResults(state, result) {
    state.periodicalAncBrDnFinalResult = result;
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  // fetchPeriodicalBrDnSourceDatas(context, queryParams) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post('sourcedata/get/periodicalbrdn', queryParams, this)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   });
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
