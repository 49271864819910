import ApiService from '@/common/api.service'

/* eslint-disable */
const getDefaultState = () => {
  return {
  }
};

const state = getDefaultState();

const getters = {
}

const mutations = {
}

const actions = {
  saveTraitSurvey(context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(`TraitSurvey`, params, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  fetchTraitSurvey(context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(`TraitSurvey/get`, params, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
