import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

/* eslint-disable */
const state = {
  ticketData: null,
  ticketList: [],
}

const getters = {
  getTicketData(state) {
    return state.ticketData;
  },
  getTicketList(state) {
    return state.ticketList;
  },
}

const mutations = {
  setTicketData(state, ticketData) {
    state.ticketData = ticketData;
  },
  setTicketList(state, ticketList) {
    state.ticketList = ticketList;
  },
}

const actions = {
  fetchTickets(context, queryParams) {
    if (context.state.ticketList.length > 0) {
      return;
    }

    return ApiService.get('ticket', queryParams, this)
    .then(response => {
      if (response.statusCode === 200) {
        context.commit('setTicketList', response.result)
      } else {
        AlertService.error(this._vm, response.message)
      }
    });
  },

  fetchTicket(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`ticket/${queryParams.ticketNo}`, {}, this)
        .then(response => {
          context.commit('setTicketData', response.result);
          resolve(response);
        })
        .catch(error => reject(error))
    });
  },

  createTicket(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('ticket', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  createTicketDetail(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('ticket/message', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
