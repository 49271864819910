import ApiService from '@/common/api.service'
/* eslint-disable */
const getDefaultState = () => {
  return {
    modernSourceDatas: [],
    modernSourceDatasResult: [],
    closestModernPopResults: null,
    closestModerPopNumber: '30',
  }
};

const state = getDefaultState();

const getters = {
  getClosestModernPopResults(state) {
    return state.closestModernPopResults;
  },
}

const mutations = {
  setModernSourceDatas(state, sourceDatas) {
    if (sourceDatas.length) {
      const resolveSource = this.state.orderResult.IllusDnaService.codeDataToArray(sourceDatas[0].source);
      if (resolveSource.isSuccess) {
        state.modernSourceDatasResult = resolveSource.result;
        state.modernSourceDatas = resolveSource.result.map( item => {
          return {
            item: item,
            isActive: true,
          }
        });
      }
    }
    
    if (state.modernSourceDatas.length) {
      let filteredData = state.modernSourceDatas.filter(item => item.isActive === true);
      filteredData = filteredData.map(item => item.item);
      this.state.orderResult.IllusDnaService.onSetSource(filteredData);
    }
  },
  runModernDist(state) {
    this.state.orderResult.IllusDnaService.dataObject.maxDistOutPut = state.closestModerPopNumber
    const result = this.state.orderResult.IllusDnaService.calculateDistance(0);
    state.closestModernPopResults = result;
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchModernSourceDatas(context, queryParams) {
    return ApiService.post('sourcedata/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setModernSourceDatas', response.result);
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
  fetchModernSourceDatasSilent(context, queryParams) {
    return ApiService.postSilent('sourcedata/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setModernSourceDatas', response.result);
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
