import ApiService from '@/common/api.service'
import { PRODUCT_CODES, G25_CHECKOUT_STEPS } from '@/common/config';
import { getField, updateField } from 'vuex-map-fields';

/* eslint-disable */
let kitObj = { 
  id: 0, 
  personName: '', 
  fileName: '', 
  presetEthnicityId: null,
  selectedRegionName: 'Select Ethnicity',
  isEmptyPresetEthnicityId: false,
  targetCoordinate: '', 
  preTempOrderRawDataGuid: null,
  emptyPreTempOrderRawDataGuid: false,
  isLoading: false,
  emptyPersonName: false,
  emptyTargetCoordinate: false,
  isShowDropdown: false,
};
const persistedState = JSON.parse(localStorage.getItem('g25Checkout'));

const getDefaultState = () => {
  return {
    selectedProductCode: PRODUCT_CODES.UPLOAD_G25_RAW_DATA,
    selectedStepCode: G25_CHECKOUT_STEPS.SELECT_SERVICE,

    productData: null,
    userData: {
      userEmail: null,
      firstName: null,
      lastName: null,
      password: null,
      twoFactorAuthActive: true,
    },
    createOrderData: {
      subtotal: null,
      discountSubtotal: null,
      currencyUnit: null,
      paymentType: 2,
      paymentGuid: null,
      product: null,
      note: null,
      productQuantity: 1,
      kits: [Object.assign({}, kitObj)],
      isFinishedPayment: false,
      preTempOrderGuid: null,
      promotionDetailGuid: null,
      isConfirmPrivacyPalicy: false,
    },
  }
}

const state = persistedState ? persistedState : getDefaultState()

const getters = {
  getCreateOrderData(state) {
    return state.createOrderData;
  },
  getCheckoutQuery(state) {
    const product = state.productData.product;
    const orderData = state.createOrderData;
    
    const queryParams = {
      subtotal: orderData.subtotal.toString().replace(',', '.'),
      discountSubtotal: orderData.discountSubtotal != null ? orderData.discountSubtotal.toString().replace(',', '.') : null,
      productQuantity: orderData.productQuantity,
      currencyUnit: product.currencyUnit,
      productId: product.id,
      paymentType: orderData.paymentType,
      paymentGuid: orderData.paymentGuid,
      promotionDetailGuid: orderData.promotionDetailGuid,
      preTempOrderGuid: orderData.preTempOrderGuid,
      personList: orderData.kits,
    };
    return queryParams;
  },
  getOrderSubTotal(state) {
    return state.createOrderData.discountSubtotal === null ? state.createOrderData.subtotal : state.createOrderData.discountSubtotal;
  },
  getProductData(state) {
    return state.productData;
  },

  // User
  getUserDataField(state) {
    return getField(state.userData);
  },
  getUserData(state) {
    return state.userData;
  },
}

const mutations = {
  setProductData(state, productData) {
    state.productData = productData;
  },
  setCreateOrderData(state, { key, value }) {
    state.createOrderData[key] = value;
  },

  setOrderFileData(state, { key, value, index }) {
    state.createOrderData.kits[index][key] = value;
  },
  addOrderFile(state, fileItem) {
    const findFileObj = state.createOrderData.kits.find(x => x.id == fileItem.id);
    if (findFileObj) {
      findFileObj.fileName = fileItem.fileName;
      findFileObj.preTempOrderRawDataGuid = fileItem.preTempOrderRawDataGuid;
      findFileObj.emptyPreTempOrderRawDataGuid = false;
    }
  },
  removeOrderFile(state, fileItem) {
    const findFileObj = state.createOrderData.kits.find(x => x.id == fileItem.id);
    if (findFileObj) {
      findFileObj.fileName = '';
      findFileObj.preTempOrderRawDataGuid = null;
      findFileObj.emptyPreTempOrderRawDataGuid = true;
    }
  },

  addOrderCoordinate(state, coordinateData) {
    const findFileObj = state.createOrderData.kits.find(x => x.id == coordinateData.fileItem.id);
    if (findFileObj) {
      findFileObj.targetCoordinate = coordinateData.targetCoordinate;
      findFileObj.emptyTargetCoordinate = false;
    }
  },

  changeFileArray(state, count) {
    /* eslint-disable */
    state.createOrderData.productQuantity = count;
    const fileLength = state.createOrderData.kits.length;
    if(count > fileLength) {
      for (let index = fileLength; index < count; index++) {
        const newFile = Object.assign({}, kitObj);
        newFile.id = index;
        state.createOrderData.kits.push(newFile)
      }
    }

    if(count < fileLength) {
      for (let index = 0; index < fileLength - count; index++) {
        state.createOrderData.kits.pop();
      }
    }
  },

  resetOrderState(state) {
    kitObj = { 
      id: 0, 
      personName: '', 
      fileName: '', 
      presetEthnicityId: null,
      selectedRegionName: 'Select Ethnicity', 
      isEmptyPresetEthnicityId: false,
      targetCoordinate: '',
      preTempOrderRawDataGuid: null,
      emptyPreTempOrderRawDataGuid: false,
      isLoading: false,
      emptyPersonName: false,
      emptyTargetCoordinate: false,
    };
    Object.assign(state, getDefaultState())
  },

  closeDropdowns(state) {
    state.createOrderData.kits.forEach(element => {
      element.isShowDropdown = false;
    });
  },

  // User
  updateUserDataField(state, field) {
    updateField(state.userData, field);
  },
}

const actions = {
  saveChecout(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('Checkout/g25', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  createPaymentIntent(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.postSilent('payment/create/intent', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
  createPaymentDirect(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('order/payment/create/direct', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  applyPromotionCode(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.postSilent('Promotion/apply/code', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
