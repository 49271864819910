/* eslint-disable */
const farmerHgsHelpers = {
  // Farmer HGS
  _SetFarmerHGsResult(state, selectedResultId, result) {
    this._SetFarmerHGsABResults(state.farmerAndHGsResults, selectedResultId, result);
  },

  _SetFarmerHGsABResults(stateResults, selectedResultId, result) {
    const tempRegionResults = JSON.parse(JSON.stringify(result));
    tempRegionResults.outPuts = [];
    result.outPuts.forEach(value => {
      const splitValue = value.resultsTable.split(':');
      if (splitValue.length > 0) {
        let findValue = tempRegionResults.outPuts.find(x => x.resultsTable === splitValue[0]);
        if (findValue) {
          findValue.currentResult += value.currentResult;
        } else {
          value.resultsTable = splitValue[0];
          tempRegionResults.outPuts.push(value);
        }
      }
    });
    tempRegionResults.outPuts.sort((a, b) => b.currentResult - a.currentResult);
    const farmerHGsResult = stateResults.find(x => x.selectedResultId === selectedResultId);
    if (farmerHGsResult) {
      farmerHGsResult.result = tempRegionResults;
    } else {
      stateResults.push({
        selectedResultId: selectedResultId,
        result: tempRegionResults,
      });
    }
  },
}

export default farmerHgsHelpers;