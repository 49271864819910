import ApiService from '@/common/api.service'
import breakDownHelpers from './breakDownHelpers';
/* eslint-disable */
const getDefaultState = () => {
  return {
    customAncBrDnnPop: 3,
    customAncBrDnSourceData: null,
    isSelectAllCustomAnBrDnSourceData: true,
    customAncBrDnSourceDataActive: [],
    customAncBrDnResults: [],
    customAncBrDnFinalResult: [],
  }
};

const state = getDefaultState();

const getters = {
  getCustomAncBrDnResult(state, getters, rootState) {
    const findResult = state.customAncBrDnResults.find(x => x.selectedResultId === rootState.orderResult.selectedResultId);
    if (findResult) {
      return findResult.result;
    }
    return [];
  },
  getCustomAncBrPureResult(state, getters, rootState) {
    const findResult = state.customAncBrDnResults.find(x => x.selectedResultId === rootState.orderResult.selectedResultId);
    if (findResult) {
      return findResult.pureResult;
    }
    return [];
  },
  getCustomAncBrDnFinalResult(state) {
    return state.customAncBrDnFinalResult;
  },
}

const mutations = {
  setSelectedCalculator(state, calculator) {
    state.selectedAncientCalculator = calculator;
  },
  setCustomAnBrDnResult(state, customAnBrDnResultData) {
    breakDownHelpers._SetCustomizeSourceDataActive(state, this.state.orderResult.IllusDnaService, customAnBrDnResultData.source);
    
    state.customAncBrDnSourceData = customAnBrDnResultData.source;
    if (customAnBrDnResultData.isHaseResult && customAnBrDnResultData.result !== '[]') {
      breakDownHelpers._SetCustomAncBrDnResult(state, this.state.orderResult.selectedResultId, JSON.parse(customAnBrDnResultData.result));
    }
  },
  runSingleCustomAnBrDn(state) {
    const tempPop = this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation;
    this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation = state.customAncBrDnnPop;

    const resolveSource = this.state.orderResult.IllusDnaService.codeDataToArray(state.customAncBrDnSourceData);
    if (resolveSource.isSuccess) {
      this.state.orderResult.IllusDnaService.onSetSource(resolveSource.result);
      if(state.customAncBrDnSourceDataActive.length) {
        breakDownHelpers._FilterCustomizSourceData(this.state.orderResult.IllusDnaService, state.customAncBrDnSourceDataActive);
      }
    }
    
    const result = this.state.orderResult.IllusDnaService.runABAlgorithm();
    
    breakDownHelpers._SetCustomAncBrDnResult(state, this.state.orderResult.selectedResultId, result);
    this.state.orderResult.IllusDnaService.dataObject.numberOfPopulation = tempPop;
  },
  setCustomAncBrDnResultFinalResults(state, result) {
    state.customAncBrDnFinalResult = result;
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchCustomBrDnSourceDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('sourcedata/getcustomanbrdn', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
