import { runCustomMCAlgorithm, runABByPopNumber, callResultData, callResultDataRdc, callResponseData, makeNewArray, subDataArray, divideToNestTarget, divideToNestSource, CalculateModernPopColor } from './utils'

/* eslint-disable */
const IllusDnaService = { 
  dataArrays: {
    sourceCodeArray: [],
    targetCodeArray: [],
    sourceCodeNumber: null,
    targetCodeNumber: null,
  },
  dataObject: {
    codeDimensions:  null,
    maxDistOutPut: 30,
    nestlength: 500,

    distanceColumnMult: 1,
    numberOfPopulation: 0,

    loopMult: 1,
    recalculate: 1,
  },

  // Common Data
  onSetSource(sourceArray) {
    this.dataArrays.sourceCodeArray = sourceArray;
    this.dataArrays.sourceCodeNumber = sourceArray.length;
    this.dataObject.codeDimensions = sourceArray[0].length - 1;
  },

  onSetTarget(targetArray) {
    this.dataArrays.targetCodeArray = targetArray;
    this.dataArrays.targetCodeNumber = targetArray.length;
  },

  codeDataToArray(codeData) {
    const response = {
      isSuccess: true,
      message: '',
      result: [],
    };
    
    let codeDataLines = codeData.trim().replace(/\r\n/g, "\n").replace(/\"/g, "").replace(/\</g, "&lt;").replace(/\>/g, "&gt;").split("\n");
    if(codeDataLines) {
      let columnDimension = codeDataLines[0].split(",").length;
      if(columnDimension > 1) {
        for(let index = 0; index < codeDataLines.length; index++) {
          codeDataLines[index] = codeDataLines[index].split(',');
          for(let subIndex = 1;  subIndex < codeDataLines[index].length; subIndex ++) {
            codeDataLines[index][subIndex] = Number(codeDataLines[index][subIndex]);
          }
        }
      } else {
        response.message += "Error, Data isn't valid.";
        response.isSuccess = false;
      }
    }

    response.result = codeDataLines;
    return response;
  },

  calculateDistance(targetIndex = 0) {
    let sourCodeNumber = this.dataArrays.sourceCodeNumber;
    const distanceResult = makeNewArray(sourCodeNumber, 0);
    const targetCode = this.dataArrays.targetCodeArray[targetIndex].slice();
    const sourceCodeArray = this.dataArrays.sourceCodeArray;

    for (let index = 0; index < sourCodeNumber; index++) {
      let callDistance = subDataArray(targetCode, sourceCodeArray[index]);
      callDistance.shift();
      callDistance = callDistance.map((element) => element ** 2);
      callDistance = callDistance.reduce((t, n) => t+n);
      callDistance = Math.sqrt(callDistance);
      distanceResult[index] = distanceResult[index].concat(sourceCodeArray[index][0]);
      distanceResult[index].push(callDistance);
    }
    distanceResult.sort((a, b) => a[1] - b[1]);
    if (targetCode[0] === distanceResult[0][0]) {
      distanceResult.shift();
      sourCodeNumber--;
    }
    let maxDistOutPut =  sourCodeNumber < this.dataObject.maxDistOutPut ? sourCodeNumber: this.dataObject.maxDistOutPut;

    const results = [];
    for (let index = 0; index < maxDistOutPut; index++) {
      const gradHSL = CalculateModernPopColor((distanceResult[index][1] * 100).toFixed(3));
      results.push({ gradHSL, distance: (distanceResult[index][1] * 100).toFixed(3), title: distanceResult[index][0] });
    }

    return results;
  },

  runABAlgorithm() {
    let result = undefined, resultData= undefined;
    let sourceLength = this.dataArrays.sourceCodeNumber;
    const targetData = divideToNestTarget(this.dataArrays.targetCodeArray, this.dataObject.nestlength, this.dataObject.codeDimensions);
    const sourceData = divideToNestSource(this.dataArrays.sourceCodeArray, this.dataArrays.sourceCodeNumber, this.dataObject.nestlength, this.dataObject.codeDimensions);
    const adcValue = this.dataObject.distanceColumnMult === 1 ? false : this.dataObject.distanceColumnMult;

    const dataParams = {
      sourceCodData: sourceData,
      sourceCodeNumber: this.dataArrays.sourceCodeNumber,
      targetCode: targetData,
    };

    const checkParams = {
      loopMult: this.dataObject.loopMult,
      recalculate: this.dataObject.recalculate,
      distanceColumnMult: adcValue,
    };

    const extraParams = { 
      nestlength: this.dataObject.nestlength,
      codeDimensions: this.dataObject.codeDimensions,
    };

    if (this.dataObject.numberOfPopulation) {
      dataParams.sourceCodeArray = this.dataArrays.sourceCodeArray;
      checkParams.distanceColumnMult = undefined;
      result = runABByPopNumber(dataParams, checkParams, extraParams, this.dataObject.numberOfPopulation);
      resultData = callResultDataRdc(result);
    } else {
      result = runCustomMCAlgorithm(dataParams, checkParams, extraParams);
      resultData = callResultData(result, this.dataArrays.sourceCodeArray,sourceLength);
    }
    
    resultData.sort((a, b) => b[1] - a[1]);

    return callResponseData(result, resultData);
  },
}

export default IllusDnaService;
