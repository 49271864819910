<template>
  <div class="crud-buttons">
    <b-button-group>
      <b-button v-if="showRefresh" @click="onClickRefresh"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="sm"
      >
       <b-spinner
          v-if="$store.getters.getLoader"
          class="mr-1"
          small
          variant="light"
        />
        <feather-icon
          v-else
          icon="RefreshCcwIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Refresh') }}</span>
      </b-button>
      <b-button v-if="showAddNew" @click="onClickAddNew"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="warning"
        size="sm"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Add') }}</span>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import { BButton, BButtonGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'CrudButton',
  components: {
    BButtonGroup,
    BButton,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      listLoading: false,
    }
  },
  props: {
    showRefresh: {
      type: Boolean,
      default: true,
    },
    showAddNew: {
      type: Boolean,
      default: true,
    },

    onClickRefresh: {
      type: Function,
      default() {
        return {}
      },
    },

    onClickAddNew: {
      type: Function,
      default() {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.crud-buttons {
  margin: -10px 0 10px 0 !important;
}
</style>
