/* eslint-disable */
export const generateRandomRange = (min, max) => {
  return Math.floor(Math.random() * (max - min) ) + min;
}

export const makeNewArray = (dim1, dim2) => {
  const newArr = new Array(dim1);
  for(let i = 0; i < newArr.length; i++) {
      newArr[i] = new Array(dim2);
  }
  return newArr;
}

export const subDataArray = (array1, array2) => {
  return array1.map((value, index) => value - array2[index]);
}


export const addDataArray = (array1, array2) => {
  return array1.map((value, index) => value + array2[index]);
}

export const callArraySum = (arr) => {
  return arr.reduce((t, n) => t+n);
}

export const clusterResults = (resultsDatas, sourceLength) => {
  let i, popTitles, addedTitles;
  for (i = 0; i < sourceLength; i++) {
    popTitles = resultsDatas[i][0].split(":");
    resultsDatas[i][0] = popTitles[0];
  }
  resultsDatas.sort( function(a, b) {
    return a[0].localeCompare(b[0]);
  });
  for (i = sourceLength - 2; i > -1; i--) {
    if (resultsDatas[i][0] == resultsDatas[i + 1][0]) {
      addedTitles = resultsDatas[i][0];
      resultsDatas[i] = addDataArray(resultsDatas[i],resultsDatas[i + 1]);
      resultsDatas[i][0] = addedTitles;
      resultsDatas.splice(i + 1, 1);
    }
  }
  return resultsDatas;
}

export const divideToNestTarget = (targetData, number, dims) => {
  const target = targetData[0].slice();
  target.shift();
  for (let i = 0; i < dims; i++) {
      target[i] = target[i] / number;
  }
  return target;
}

export const divideToNestSource = (sourceData, sourceNumber, number, dims) => {
  let i, j, tempLine;
  const source = Array(sourceNumber);
  for (i = 0; i < sourceNumber; i++) {
      tempLine = sourceData[i].slice();
      tempLine.shift();
      source[i] = tempLine.slice();
      for (j = 0; j < dims; j++) {
          source[i][j] = source[i][j] / number;
      }
  }
  return source;
}

export const callResultData = (result, sourceData, sourceLength) => {
  const resultData = Array(sourceLength)
  for (let i = 0; i < sourceLength; i++) {
    resultData[i] = Array(2);
    resultData[i][0] = sourceData[i][0];
    resultData[i][1] = result.points[i];
  }
  return resultData;
};

export const callResultDataRdc = (result) => {
  let sourceLength = result.points.length;
  const resultData = Array(sourceLength)
  for (let i = 0; i < sourceLength; i++) {
    resultData[i] = Array(2);
    resultData[i][0] = result.titles[i];
    resultData[i][1] = result.points[i];
  }
  return resultData;
};

export const callResponseData = (result, resultData) => {
  const response = {
    result: (100 * result.dist).toFixed(3),
    outPuts: [],
  };
  for (let i = 0; i < resultData.length; i++) {
    if (resultData[i][1] != 0) {
        response.outPuts.push({
          currentResult: resultData[i][1] * 100,
          resultsTable: resultData[i][0],
        });
    }
  }

  return response;
};

// runCustomMCAlgorithm
export const runCustomMCAlgorithm = (dataParams, checkParams, extraParams) => {
  let arrangements = Array(); 
  const loopSize = Math.ceil((dataParams.sourceCodeNumber * checkParams.loopMult) / 4);
  const points = Array(dataParams.sourceCodeNumber).fill(0);
  const longestNumber = 100000000000000000;
  
  checkDistanceColumnMultiSub(dataParams, checkParams, extraParams);
  let currentNests = Array(extraParams.nestlength).fill(-1);
  currentNests = makeRandomNests(currentNests, dataParams.sourceCodeNumber);
  let currentPoints = makePointsFromNests(currentNests, dataParams, extraParams.codeDimensions);
  let currentDists = runDistance(currentPoints);

  let nextNests = null, nextPoints = null, nextDists = null;
  for (let i=0; i< loopSize; i++) {
    nextNests = makeRandomNests(currentNests, dataParams.sourceCodeNumber);
    for (let j = 0; j < extraParams.nestlength; j++) {
      nextPoints = subDataArray(currentPoints, dataParams.sourceCodData[currentNests[j]]);
      nextPoints = addDataArray(nextPoints, dataParams.sourceCodData[nextNests[j]]);
      nextDists = runDistance(nextPoints);
      if (nextDists < currentDists) {
        currentNests[j] = nextNests[j];
        currentPoints = nextPoints;
        currentDists = nextDists;
      }
    }
  }
  
  fillPoints(points, currentNests, extraParams.nestlength);
  fillArrangements(arrangements, points, dataParams.sourceCodeNumber);

  let arrangeNumber = arrangements.length;
  let prevDists;
  function reRunArrange() {
    currentDists = Math.round(longestNumber * currentDists);
    do {
      prevDists = currentDists;
      for (let i = arrangeNumber - 1; i > -1; i--) {
          if (arrangements[i][1] > 0) {
              for (let j = 0; j < arrangeNumber; j++) {
                  if (i == j) { continue; }
                  nextPoints = subDataArray(currentPoints, dataParams.sourceCodData[arrangements[i][0]]);
                  nextPoints = addDataArray(nextPoints, dataParams.sourceCodData[arrangements[j][0]]);
                  nextDists = Math.round(longestNumber * runDistance(nextPoints));
                  if (nextDists < currentDists) {
                      arrangements[i][1]--;
                      arrangements[j][1]++;
                      currentPoints = nextPoints;
                      currentDists = nextDists;
                      break;
                  }
              }
          }
      }
    }
    while (currentDists < prevDists);
  }
  reRunArrange();
  for (let i = 0; i < arrangeNumber; i++) {
    points[arrangements[i][0]] = arrangements[i][1];
  }

  if (checkParams.distanceColumnMult && checkParams.recalculate) {
    extraParams.codeDimensions--;
    currentPoints.pop();
    currentDists = runDistance(currentPoints);
    for (let i = 0; i < dataParams.sourceCodeNumber; i++) {
      dataParams.sourceCodData[i].pop();
    }
    arrangements = [];
    for (let i = 0; i < dataParams.sourceCodeNumber; i++) {
      if (points[i] > 0) {
        arrangements.push([i, points[i]]);
      }
    }
    arrangements.sort((a, b) => b[1] - a[1]);
    arrangeNumber = arrangements.length;
    reRunArrange();
    for (let i = 0; i < arrangeNumber; i++) {
        points[arrangements[i][0]] = arrangements[i][1];
    }
  }
  for (let i = 0; i < dataParams.sourceCodeNumber; i++) {
    points[i] = points[i] / extraParams.nestlength;
  }
  if (checkParams.distanceColumnMult && !checkParams.recalculate) { currentPoints.pop(); }
  currentDists = runDistance(currentPoints);
  
  return { 
    dist: Number(Math.sqrt(currentDists).toFixed(8)),
    points: points,
  };
}

// runCustomMCAlgorithm Functions
export const checkDistanceColumnMultiSub = (dataParams, checkParams, extraParams) => {
  if (checkParams.distanceColumnMult) {
    checkParams.distanceColumnMult = checkParams.distanceColumnMult/ 8;
    extraParams.codeDimensions = extraParams.codeDimensions + 1;
    for (let i = 0; i < dataParams.sourceCodeNumber; i++) {
      dataParams.sourceCodData[i] = subDataArray(dataParams.sourceCodData[i], dataParams.targetCode);
      dataParams.sourceCodData[i].push(checkParams.distanceColumnMult * Math.sqrt(runDistance(dataParams.sourceCodData[i])));
    }
  }
  else {
    for (let i = 0; i < dataParams.sourceCodeNumber; i++) {
      dataParams.sourceCodData[i] = subDataArray(dataParams.sourceCodData[i], dataParams.targetCode);
    }
  }
}

export const runDistance = (paramsData) => {
  let result = paramsData.map((element) => element ** 2);
  if (result) {
    result = result.reduce((t, n) => t+n)
  }
  return result;
}

export const makeRandomNests = (prevNests, sourceCodeNumber) => {
  let newNests = Array(prevNests.length);
  for(let i = 0; i < prevNests.length; i++) {
    newNests[i] = generateRandomRange(0, sourceCodeNumber);
    while (newNests[i] == prevNests[i]) {
      newNests[i] = generateRandomRange(0, sourceCodeNumber);
    }
  }
  return newNests;
}

export const makePointsFromNests = (nests, dataParams, codeDimensions) => {
  let newPoints = Array(codeDimensions).fill(0), tempPoint;
  for(let i = 0; i < nests.length; i++) {
    tempPoint = dataParams.sourceCodData[nests[i]].slice();
    newPoints = addDataArray(newPoints, tempPoint);
  }
  return newPoints;
}

export const fillPoints = (points, nests, nestlength) => {
  for (let i = 0; i < nestlength; i++) {
    points[nests[i]] += 1; 
  }
}

export const fillArrangements = (ranks, points, sourceCodeNumber) => {
  for (let i = 0; i < sourceCodeNumber; i++) {
    if (points[i] > 0) {
      ranks.push([i, points[i]]);
    }
  }
  ranks.sort((a, b) => b[1] - a[1]);
}

// Run AncestryBreakDown By Number Of Pop
export const runABByPopNumber = (dataParams, checkParams, extraParams, numberOfPopulation) => {
  let titleArr = [];
  let idArray = [];
  let currentResult = undefined, nextResult = undefined;
  
  for (let i = 0, tempArr; i < dataParams.sourceCodeNumber; i++) {
    tempArr = [dataParams.sourceCodeArray[i][0].split(':').shift(), dataParams.sourceCodeArray[i][0]];
    dataParams.sourceCodData[i] = tempArr.concat(dataParams.sourceCodData[i]);
  }

  dataParams.sourceCodData = clusterArray(dataParams.sourceCodData);
  for(let i in dataParams.sourceCodData) {
    titleArr.push([]);
    for(let j in dataParams.sourceCodData[i]) {
      dataParams.sourceCodData[i][j].shift();
      titleArr[i].push(dataParams.sourceCodData[i][j].shift());
      idArray.push(i);
    }
  }

  let sourceLength = dataParams.sourceCodData.length;
  let initData = [];
  for(let countIndex = 0; countIndex < sourceLength; countIndex++) {
    initData.push(countIndex);
  }

  let initDataResult = [];
  runInitDataMCAlgorithms(initData, initDataResult, idArray, dataParams, extraParams.codeDimensions);
  initDataResult = clusterResults(initDataResult, initDataResult.length)
  initDataResult.sort((a,b) => b[1] - a[1]);

  let newTitleArr = [];
  let newSourceData = [];
  for (let item in initDataResult) {
    if (Number(initDataResult[item][1]) > 0.02) {
        newSourceData.push(dataParams.sourceCodData[Number(initDataResult[item][0])]);
        newTitleArr.push(titleArr[Number(initDataResult[item][0])]);
    } else {
        break;
    }
  }

  dataParams.sourceCodData = newSourceData;
  titleArr = newTitleArr;
  sourceLength = dataParams.sourceCodData.length;

  let actualNestLength = extraParams.nestlength;
  let currentData = [];
  let nextData = undefined;
  if (sourceLength <= numberOfPopulation) {
    for (let i = 0; i < sourceLength; i++) {
      currentData.push(i);
    }
    return CompleteProcess();
  } else {
    for (let i = 0; i < numberOfPopulation; i++) {
      currentData.push(i);
    }
  }

  let counter = 0;
  let copyDataParams = {
    targetCode: dataParams.targetCode,
  };
  function runMCAlgorithm(setToRun) {
    counter++;
    let currentSource = [];
    for (let item of setToRun) {
        currentSource = currentSource.concat(dataParams.sourceCodData[item]);
    }
    
    if (!copyDataParams) {
      copyDataParams = {
        targetCode: dataParams.targetCode,
      };
    }
    copyDataParams.sourceCodData = currentSource;
    copyDataParams.sourceCodeNumber = currentSource.length;
    
    return runCustomMCAlgorithm(copyDataParams, checkParams, extraParams);
  }
  
  const storeSet = currentData;
  let runs = [];
  for (let i = 0, n = 30 + sourceLength; i < n; i++) {
      currentData = storeSet.slice();
      currentResult = runMCAlgorithm(currentData);
      extraParams.nestlength = 35;
      for (let i = 0, n = Math.ceil(sourceLength); i < n; i++) {
          for (let j = 0; j < numberOfPopulation; j++) {
              nextData = currentData.slice();
              nextData[j] = callNewPopulation(nextData[j], currentData, sourceLength);
              nextResult = runMCAlgorithm(nextData);
              if (nextResult.dist < currentResult.dist) {
                  currentResult = nextResult;
                  currentData = nextData;
              }
          }
      }
      runs.push([currentResult.dist, currentData.slice()]);
  }
  runs.sort((a, b) => a[0] - b[0]);
  currentData = runs[0][1];
  function CompleteProcess() {
    extraParams.nestlength = actualNestLength;
    currentResult = runMCAlgorithm(currentData);
    currentResult.titles = callNames(titleArr, currentData);
    currentResult.sourceLength = sourceLength;
    currentResult.counter = counter;
    return currentResult;
  };

  return CompleteProcess();
}

// Functions ABBNP
export const clusterArray = (arr) => {
  let sortArr = arr.slice(), clusterArr = [];
  sortArr.sort((a, b) => a[0].localeCompare(b[0]));
  let name = null, i = 0, j = -1;
  for (i = 0; i < sortArr.length; i++) {
      if (sortArr[i][0] != name) {
          j++;
          name = sortArr[i][0];
          clusterArr.push([]);
      }
      clusterArr[j].push(sortArr[i]);
  }
  return clusterArr;
}

export const runInitDataMCAlgorithms = (initData, initDataResult, idArray, dataParams, codeDimensions) => {
  let initResult = [
    runDATAMCAlgorithm(dataParams, initData, 50, 5, codeDimensions, 0.5, true),
    runDATAMCAlgorithm(dataParams, initData, 50, 5, codeDimensions, 0, false),
    runDATAMCAlgorithm(dataParams, initData, 50, 5, codeDimensions, 1, true),
    runDATAMCAlgorithm(dataParams, initData, 50, 5, codeDimensions,  0, false),
    runDATAMCAlgorithm(dataParams, initData, 50, 5, codeDimensions, 2, true),
    runDATAMCAlgorithm(dataParams, initData, 500, 2, codeDimensions, 0, false)
  ];
  for (let item in idArray) {
      for (let item2 in initResult) {
        initDataResult.push([idArray[item], initResult[item2].points[item]]);
      }
  }
}

export const runDATAMCAlgorithm = (dataParams, initData, nestlength, loopMult, codeDimensions, distanceColumnMult, recalculate) => {
  let copyDataparams = {
    targetCode: dataParams.targetCode,
  };

  let currentSource = [];
  for (let item of initData) {
      currentSource = currentSource.concat(dataParams.sourceCodData[item]);
  }
  copyDataparams.sourceCodData = currentSource;
  copyDataparams.sourceCodeNumber = currentSource.length;
  let extraParams = { nestlength: nestlength, codeDimensions: codeDimensions };
  let checkParams = { loopMult: loopMult, distanceColumnMult: distanceColumnMult, recalculate: recalculate };
  return runCustomMCAlgorithm(copyDataparams, checkParams, extraParams);
}

export const callNames = (titleArr, setToDataRun) => {
  let names = [];
  for (let item of setToDataRun) {
    names = names.concat(titleArr[item]);
  }
  return names;
}

export const callNewPopulation = (currentDataItem, currentData, sourceLength) => {
  let newPop = generateRandomRange(0, sourceLength);
  while (newPop == currentDataItem || currentData.includes(newPop)) {
      newPop = generateRandomRange(0, sourceLength);
  }
  return newPop;
}


// Color Functions
export const CalculateModernPopColor = (value) => {
  if(value > 0 && value <= 1) {
    return '#99da44';
  }
  if(value > 1 && value <= 2) {
    return '#7abb25';
  }
  if(value > 2 && value <= 3) {
    return '#7abb25';
  }
  if(value > 3 && value <= 4) {
    return '#f8961e';
  }
  if(value > 4 && value <= 5) {
    return '#f3722c';
  }
  if(value > 5 && value <= 6) {
    return '#f94144';
  }
  if(value > 6 && value <= 7) {
    return '#168aad';
  }
  if(value > 7 && value <= 8) {
    return '#168aad';
  }
  if(value > 8 && value <= 9) {
    return '#168aad';
  }
  if(value > 9 && value <= 10) {
    return '#168aad';
  }
  return '#168aad';
}

export const CalculateModernMapPopColor = (value) => {
  if(value > 0 && value <= 0.5) {
    return '#FF0A54';
  }

  if(value > 0.5 && value <= 1) {
    return '#FF155C';
  }
  if(value > 1 && value <= 1.5) {
    return '#FF155C';
  }
  if(value > 1.5 && value <= 2.0) {
    return '#FF2B6B';
  }
  if(value > 2 && value <= 2.5) {
    return '#FF3673';
  }
  if(value > 2.5 && value <= 3) {
    return '#FF417A';
  }
  if(value > 3 && value <= 3.5) {
    return '#FF4C82';
  }
  if(value > 3.5 && value <= 4) {
    return '#FF578A';
  }
  if(value > 4 && value <= 4.5) {
    return '#FF6291';
  }
  if(value > 4.5 && value <= 5) {
    return '#FF6D99';
  }
  if(value > 5 && value <= 5.5) {
    return '#FF79A1';
  }
  if(value > 5.5 && value <= 6) {
    return '#FF84A9';
  }
  if(value > 6 && value <= 6.5) {
    return '#FF8FB0';
  }
  if(value > 6.5 && value <= 7) {
    return '#FF9AB8';
  }
  if(value > 7 && value <= 7.5) {
    return '#FFA5C0';
  }
  if(value > 7.7 && value <= 8) {
    return '#FFB0C7';
  }
  if(value > 8 && value <= 8.5) {
    return '#FFBBCF';
  }
  if(value > 8.5 && value <= 9) {
    return '#FFC6D7';
  }
  if(value > 9 && value <= 9.5) {
    return '#FFD1DE';
  }
  if(value > 9.5 && value <= 20) {
    return '#FFDCE6';
  }
  return '#FFDCE6';
}